import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 500.000000 500.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd" transform="translate(0.000000,500.000000) scale(0.100000,-0.100000)">
          <path d="M2297 3585 c-37 -29 -78 -60 -90 -69 -22 -15 -22 -15 -1 -15 11 -1
52 24 91 54 39 30 73 55 76 55 2 0 39 -27 82 -60 71 -55 106 -72 104 -52 0 8
-177 142 -187 142 -4 -1 -38 -25 -75 -55z"/>
<path d="M2505 3405 c-110 -63 -208 -115 -218 -115 -11 0 -44 16 -75 35 -40
24 -94 44 -179 65 -68 16 -133 30 -146 30 -13 0 -43 -13 -68 -28 -24 -16 -69
-42 -99 -59 -121 -66 -398 -222 -448 -252 -29 -17 -54 -31 -56 -31 -6 0 -281
-162 -290 -171 -6 -5 29 -9 86 -9 l95 0 84 49 c46 26 128 73 181 105 54 31
100 56 102 56 3 0 31 15 63 34 175 102 346 196 358 196 11 0 238 -133 654
-382 63 -38 125 -68 140 -68 46 0 231 23 231 29 0 4 -120 78 -266 166 -147 88
-267 165 -266 170 0 10 77 55 239 142 90 47 105 53 121 41 10 -7 132 -103 271
-213 140 -110 313 -246 385 -302 l131 -103 260 0 260 1 -26 22 c-15 12 -71 54
-125 94 -55 39 -132 96 -172 125 -192 140 -481 346 -583 415 l-97 65 -173 4
-174 4 -200 -115z"/>
<path d="M1789 2940 c-1 -22 -2 -48 -3 -57 -1 -16 10 -18 77 -21 l77 -3 0 61
0 60 -75 0 -75 0 -1 -40z"/>
<path d="M1970 2920 l0 -60 75 0 75 0 0 60 0 60 -75 0 -75 0 0 -60z"/>
<path d="M3000 2767 l0 -104 51 -11 c85 -20 80 -26 77 101 l-3 112 -62 3 -63
3 0 -104z"/>
<path d="M3170 2750 c0 -135 -1 -133 83 -145 l47 -7 0 136 0 136 -65 0 -65 0
0 -120z"/>
<path d="M1230 2670 c0 -106 4 -160 11 -160 6 0 33 10 60 21 l50 22 -3 136 -3
136 -57 3 -58 3 0 -161z"/>
<path d="M1380 2700 c0 -71 4 -130 8 -130 5 0 29 7 55 16 l47 16 0 114 0 114
-55 0 -55 0 0 -130z"/>
<path d="M1790 2800 l0 -30 75 0 75 0 0 30 0 30 -75 0 -75 0 0 -30z"/>
<path d="M1970 2800 l0 -30 75 0 75 0 0 30 0 30 -75 0 -75 0 0 -30z"/>
<path d="M2461 2777 c-127 -12 -115 -24 29 -29 99 -3 105 -2 105 17 0 24 -5
25 -134 12z"/>
<path d="M2100 2675 c-355 -36 -694 -147 -975 -318 -91 -55 -251 -174 -280
-208 l-20 -23 25 17 c36 24 212 109 295 142 39 15 100 43 137 61 353 177 711
253 1148 241 240 -6 394 -29 694 -103 304 -76 396 -93 581 -106 211 -15 367 3
471 54 85 42 94 60 22 42 -91 -23 -253 -32 -387 -23 -155 11 -297 37 -556 100
-542 131 -807 159 -1155 124z"/>
<path d="M1673 2359 c-165 -21 -361 -77 -531 -151 -95 -41 -327 -177 -379
-223 l-27 -23 74 -7 c106 -9 194 -23 276 -42 l71 -16 104 41 c141 55 223 78
370 104 108 20 157 23 389 22 292 0 403 -13 761 -88 428 -89 606 -112 797
-102 181 10 340 48 457 109 l50 26 -120 -5 c-66 -2 -149 -6 -185 -9 -108 -7
-386 13 -541 39 -203 35 -306 64 -578 160 -444 159 -523 177 -765 175 -88 -1
-189 -5 -223 -10z"/>
<path d="M1180 1655 c0 -122 2 -145 15 -145 13 0 15 23 15 145 0 122 -2 145
-15 145 -13 0 -15 -23 -15 -145z"/>
<path d="M1310 1780 c-11 -11 -20 -29 -20 -40 0 -12 -7 -20 -16 -20 -10 0 -14
-7 -12 -17 2 -10 10 -17 16 -15 9 1 12 -24 12 -89 0 -81 2 -90 18 -87 14 3 17
17 20 87 2 73 5 84 22 88 26 7 26 33 0 33 -13 0 -20 7 -20 20 0 15 7 20 25 20
28 0 34 24 9 34 -25 9 -33 7 -54 -14z"/>
<path d="M2680 1746 l0 -55 -27 15 c-35 18 -47 18 -82 0 -78 -41 -61 -176 24
-192 26 -5 41 -2 54 10 22 20 31 20 31 0 0 -9 7 -14 18 -12 15 3 17 19 20 146
3 138 3 142 -17 142 -19 0 -21 -6 -21 -54z m-23 -78 c23 -21 31 -71 15 -94 -8
-13 -46 -34 -61 -34 -6 0 -20 9 -31 20 -29 29 -27 94 2 114 30 21 47 20 75 -6z"/>
<path d="M3800 1655 c0 -138 1 -145 20 -145 11 0 20 6 20 14 0 12 4 12 30 0
34 -18 64 -12 98 19 17 16 22 31 22 70 0 40 -5 53 -29 78 -33 32 -65 37 -99
13 -22 -15 -22 -15 -22 40 0 49 -2 56 -20 56 -19 0 -20 -7 -20 -145z m134 14
c50 -39 28 -119 -34 -119 -42 0 -62 25 -58 73 2 33 8 44 28 54 33 17 33 16 64
-8z"/>
<path d="M984 1744 c-17 -40 -84 -224 -84 -230 0 -2 7 -4 15 -4 8 0 21 16 29
35 15 34 16 35 74 35 58 0 59 -1 68 -32 9 -31 44 -53 44 -29 0 7 -20 63 -45
127 -25 63 -45 119 -45 124 0 6 -9 10 -21 10 -14 0 -25 -11 -35 -36z m57 -74
l16 -50 -43 0 -43 0 13 33 c7 17 16 40 19 50 10 29 21 19 38 -33z"/>
<path d="M3037 1764 c-4 -4 -7 -16 -7 -26 0 -11 -7 -18 -20 -18 -27 0 -26 -29
1 -38 19 -8 22 -17 23 -88 1 -66 4 -79 19 -82 15 -3 17 6 17 82 0 80 1 85 22
88 33 4 37 38 4 38 -18 0 -26 6 -28 22 -3 22 -19 33 -31 22z"/>
<path d="M1464 1710 c-35 -14 -54 -50 -54 -101 0 -40 4 -51 31 -73 35 -30 70
-33 94 -9 16 16 18 16 27 0 5 -9 16 -17 23 -17 12 0 15 20 15 105 0 106 -7
124 -37 94 -8 -8 -17 -8 -31 0 -24 13 -39 13 -68 1z m84 -42 c10 -13 15 -39
13 -70 -3 -47 -78 -66 -105 -26 -34 48 -7 108 48 108 19 0 38 -6 44 -12z"/>
<path d="M1660 1645 c0 -41 5 -85 11 -99 15 -31 52 -41 89 -22 24 12 29 12 34
0 3 -8 12 -14 21 -14 12 0 15 17 15 105 0 98 -1 105 -20 105 -18 0 -20 -7 -20
-76 0 -61 -4 -79 -17 -90 -50 -38 -83 2 -83 98 0 54 -3 68 -15 68 -12 0 -15
-15 -15 -75z"/>
<path d="M1880 1705 c0 -11 12 -15 45 -15 25 0 45 -4 45 -9 0 -5 -20 -36 -45
-70 -25 -34 -45 -70 -45 -81 0 -18 7 -20 70 -20 56 0 70 3 70 15 0 11 -12 15
-50 15 -27 0 -50 2 -50 5 0 3 23 36 50 75 68 95 67 100 -20 100 -56 0 -70 -3
-70 -15z"/>
<path d="M2091 1691 c-27 -25 -31 -36 -31 -78 0 -79 56 -121 118 -89 26 14 31
14 36 1 3 -8 12 -15 21 -15 12 0 15 17 15 105 0 98 -1 105 -20 105 -11 0 -20
-7 -20 -16 0 -14 -2 -14 -22 0 -33 24 -62 19 -97 -13z m106 -25 c11 -11 16
-35 14 -68 -3 -47 -78 -66 -105 -26 -23 32 -20 65 9 94 26 26 56 26 82 0z"/>
<path d="M2310 1615 c0 -87 3 -105 15 -105 12 0 15 14 15 70 0 58 3 72 22 90
22 21 55 19 70 -4 4 -6 8 -44 8 -83 0 -66 2 -73 20 -73 18 0 20 7 20 69 0 84
-11 117 -45 133 -19 9 -31 8 -52 -3 -16 -7 -31 -15 -35 -17 -5 -2 -8 3 -8 12
0 9 -7 16 -15 16 -12 0 -15 -18 -15 -105z"/>
<path d="M2814 1710 c-54 -21 -73 -113 -34 -163 27 -34 73 -44 105 -23 30 20
35 20 35 1 0 -8 7 -15 15 -15 12 0 15 18 15 105 0 83 -3 105 -14 105 -8 0 -17
-7 -20 -16 -5 -14 -8 -14 -28 0 -25 18 -42 19 -74 6z m92 -55 c11 -17 14 -34
9 -52 -10 -41 -24 -53 -60 -53 -36 0 -50 12 -60 53 -11 42 17 77 60 77 26 0
39 -6 51 -25z"/>
<path d="M3181 1694 c-18 -14 -33 -39 -36 -58 -15 -77 53 -145 113 -115 15 7
30 15 35 17 4 2 7 -4 7 -13 0 -10 7 -15 18 -13 15 3 17 17 20 106 3 97 2 102
-18 102 -11 0 -20 -7 -20 -16 0 -14 -2 -14 -22 0 -32 23 -62 20 -97 -10z m106
-33 c15 -20 16 -66 3 -92 -13 -24 -72 -26 -94 -3 -19 19 -21 74 -3 96 24 31
70 31 94 -1z"/>
<path d="M3412 1704 c-24 -17 -30 -65 -9 -81 6 -6 29 -16 50 -23 30 -9 38 -16
35 -33 -3 -25 -43 -36 -61 -18 -16 16 -37 14 -37 -3 0 -21 61 -39 93 -27 64
24 54 80 -19 111 -24 11 -44 26 -44 34 0 20 48 32 63 17 15 -15 37 -14 37 2 0
15 -41 37 -68 37 -10 0 -28 -7 -40 -16z"/>
<path d="M3577 1640 c2 -45 7 -89 13 -99 14 -27 57 -35 86 -16 29 19 34 19 34
-1 0 -9 7 -14 18 -12 15 3 17 17 20 106 3 97 2 102 -18 102 -18 0 -20 -6 -20
-79 l0 -79 -32 -11 c-49 -18 -68 10 -68 100 0 59 -3 69 -17 69 -16 0 -18 -10
-16 -80z"/>
<path d="M1670 1370 c0 -5 7 -10 15 -10 12 0 15 -13 15 -57 0 -42 3 -54 11
-46 6 6 10 32 8 57 -4 39 -1 46 13 46 10 0 18 5 18 10 0 6 -18 10 -40 10 -22
0 -40 -4 -40 -10z"/>
<path d="M1820 1315 l0 -65 35 0 c41 0 47 17 8 22 -34 4 -38 32 -5 36 28 4 29
20 0 24 -35 5 -34 28 1 28 17 0 31 5 31 10 0 6 -16 10 -35 10 l-35 0 0 -65z"/>
<path d="M1982 1364 c-32 -22 -30 -78 3 -100 14 -9 32 -13 45 -9 27 9 26 23
-2 18 -32 -5 -50 10 -50 42 0 34 18 47 57 40 28 -4 29 -4 11 10 -25 19 -36 19
-64 -1z"/>
<path d="M2129 1375 c-11 -17 -10 -113 1 -120 6 -4 10 6 10 24 0 29 3 31 34
31 32 0 35 -3 39 -32 2 -18 4 -1 4 37 0 39 -2 58 -4 43 -4 -24 -9 -28 -38 -28
-29 0 -34 4 -37 28 -2 15 -6 23 -9 17z"/>
<path d="M2300 1313 c0 -36 3 -63 7 -59 4 4 6 25 4 46 -1 24 2 40 8 40 6 0 11
-3 11 -7 0 -5 16 -26 35 -48 l34 -40 -1 70 c-1 54 -2 59 -5 23 -2 -27 -7 -48
-11 -48 -4 0 -20 20 -37 45 -16 24 -33 45 -37 45 -5 0 -8 -30 -8 -67z"/>
<path d="M2490 1360 c-11 -11 -20 -31 -20 -45 0 -30 32 -65 60 -65 28 0 60 35
60 65 0 28 -35 65 -62 65 -10 0 -27 -9 -38 -20z m68 -12 c17 -17 15 -53 -3
-68 -8 -7 -25 -10 -40 -6 -43 11 -30 86 15 86 9 0 21 -5 28 -12z"/>
<path d="M2662 1318 l-2 -68 35 0 c19 0 35 5 35 10 0 6 -13 10 -30 10 -28 0
-29 1 -33 58 l-3 57 -2 -67z"/>
<path d="M2816 1365 c-41 -31 -16 -114 34 -115 32 0 60 30 60 65 0 54 -52 82
-94 50z m73 -27 c12 -31 -2 -62 -28 -66 -28 -4 -51 14 -51 42 0 47 63 66 79
24z"/>
<path d="M2990 1360 c-11 -11 -20 -31 -20 -45 0 -30 32 -65 60 -65 23 0 60 33
60 55 0 9 -9 15 -25 15 -14 0 -25 -4 -25 -10 0 -5 7 -10 16 -10 24 0 11 -24
-15 -28 -29 -4 -51 14 -51 43 0 30 24 48 55 43 28 -6 34 6 9 16 -27 10 -43 7
-64 -14z"/>
<path d="M3158 1350 c9 -16 20 -30 25 -30 4 0 8 -17 8 -37 l1 -38 7 36 c4 19
15 46 24 59 26 37 20 49 -7 16 l-23 -30 -18 27 c-24 37 -38 34 -17 -3z"/>
        </g>
      </svg>
    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
